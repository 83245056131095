import React, { useEffect } from "react";
import './Dashboard.css';
import Sidebar from "../components/Sidebar/index";
import Topbar from "../components/Topbar/index";
import Card from "../components/Card/index";
import { authme } from "../Utils/utils";
// import Navs from "../components/TopNav/TopNav";

const Dashboard = () => {

  useEffect(() => {
    getAuth()
  }, [])

  const getAuth = async () => {
    var res = await authme()
    if (res === null) {
      localStorage.removeItem('token')
      window.location.href = '/'
    }
  }
  return (
    <>
      <div id="wrapper">
        {/* <Navs title={'Dashboard'} /> */}
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={'Dashboard'} />
            <div className="container-fluid">
              <Card />
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default Dashboard;