import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import apiUrl from "../config/apiPath";
import config from "../config/config";
// import { Translate, I18n } from "react-redux-i18n"

export const displayErrorString = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.error(error);
}
export const displayError = (error, t) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  if (error.response.status === 422) {
    var row = [];
    Object.keys(error.response.data.errors).forEach((key, i) => {
      row.push(error.response.data.errors[key][0]);
    })
    const Msg = ({ closeToast, toastProps }) => (
      row.map((char, i) => {
        return (
          <li>
            {t(char)}
          </li>
        );
      })
    )
    toast.error(Msg);
  } else if (error.response.status === 400 || error.response.status === 401) {
    toast.error(t(error.response.data.message));
  } else {
    toast.error(t(error.message));
  }
}
export const displaySuccess = (success) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.success(success);
}
export const authme = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/me", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}

export const getRolesList = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/customer-list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getAccountantCompany = async (search = '', page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/accountant-company?search=" + search + "&page=" + page, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getCompanyDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/accountant-company/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 400) {
        window.location.href = '/accountant-company';
      } else {
        return null
      }
    });
  return response_final;
}
export const getDeleteCompany = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/accountant-company/delete/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getUser = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/user/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 400) {
        window.location.href = '/user';
      } else {
        return null
      }
    });
  return response_final;
}

export const getUserListing = async (search = '', page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/user?search=" + search + "&page=" + page, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getDeleteUser = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/user/delete/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getCompanies = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/admin/accountant-company/get-accountant-companies", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}