import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      pt: {
        translation: {
          "Add Company": "Adicionar empresa",
          "No Company": "Nenhuma empresa",
          "Fantasy Name": "Nome fantasia",
          "Legal Name": "Nome legal",
          "CNPJ": "CNPJ",
          "Created At": "Criado em",
          "Actions": "Ações",
          "Accountant Company": "Empresa de contabilidade",
          "Search": "Procurar",
          "prev": "anterior",
          "next": "próxima",
          "Add Accountant Company": "Adicionar empresa de contabilidade",
          "Back": "Voltar",
          "Submit": "Enviar",
          "Edit Accountant Company": "Editar empresa de contabilidade",
          "Are you sure?": "Tem certeza?",
          "Do you want to delete this Company?": "Deseja excluir esta empresa?",
          "Yes": "Sim",
          "Cancel": "Cancelar",
          "Do you want to delete this user?": "Deseja excluir este usuário?",
          "Name": "Nome",
          "Email": "E-mail",
          "Phone Number": "Número de telefone",
          "Phone number": "Número de telefone",
          "Role": "Papel",
          "Add User": "Adicionar usuário",
          "Users": "Usuários",
          "User": "Usuário",
          "Add User": "Adicionar usuário",
          "First Name": "Primeiro nome",
          "Last Name": "Sobrenome",
          "Password": "Senha",
          "Confirm Password": "Confirme sua senha",
          "Coordinator": "Coordenador",
          "Accountant": "Contador",
          "Select Role": "Selecione a Função",
          "Edit User": "Editar usuário",
          "Change Password": "Alterar a senha",
          "Old Password": "Senha antiga",
          "New Password": "Nova Senha",
          "Confirm Password": "Confirme sua senha",
          "password changed succesfully.": "senha alterada com sucesso.",
          "New Company has been Added": "Nova empresa foi adicionada",
          "New user has been Added": "Novo usuário foi adicionado",
          "Logout Successfull": "Sair com sucesso",
          "Company has been Updated": "A empresa foi atualizada",
          "User has been updated": "O usuário foi atualizado",
          "Login Successful": "Login bem-sucedido",
          "The fantasy name field is required.": "O campo nome fantasia é obrigatório.",
          "The legal name field is required.": "O campo de nome legal é obrigatório.",
          "The cnpj field is required.": "O campo cnpj é obrigatório.",
          "English": "Inglês",
          "Português": "Português",
          "The first name field is required.": "O campo do primeiro nome é obrigatório.",
          "The last name field is required.": "O campo sobrenome é obrigatório.",
          "The email field is required.": "O campo e-mail é obrigatório.",
          "The password field is required.": "O campo de senha é necessária.",
          "The password confirmation field is required.": "O campo de confirmação da senha é obrigatório.",
          "The role id field is required.": "O campo id da função é obrigatório.",
          "The old password field is required.": "O campo da senha antiga é obrigatório.",
          "The password confirmation does not match.": "A confirmação da senha não corresponde.",
          "Old password is not correct": "A senha antiga não está correta",
          "Login": "Conecte-se",
          "Enter your email and password to sign in": "Digite seu e-mail e senha para entrar",
          "The selected email is invalid.": "O e-mail selecionado é inválido.",
          "Invalid login credential": "Credencial de login inválida"
        }
      },
    }
  });

export default i18n;