import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import config from "../config/config";
import { authme, displayError, displaySuccess } from "../Utils/utils";
import { useTranslation } from "react-i18next";

const ChangePassword = (props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { t } = useTranslation();


  const resetPassword = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/admin/change-password', {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: confirmPassword
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('password changed succesfully.'));
        setTimeout(() => window.location.href = "/change-password", 3000);
      })
      .catch((error) => {
        displayError(error, t);
      });
  }
  useEffect(() => {
    getAuth()
  }, [])

  const getAuth = async () => {
    var res = await authme()
    if (res === null) {
      localStorage.removeItem('token')
      window.location.href = '/'
    }
  }
  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Change Password')} />
            <div className="container-fluid">
              <div className="col-xl-12">
                <div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col btnAdd">

                        {/* <h5 className="heading1 mb-0">Recent Discount Coupon</h5> */}
                        {/* <div className="btntop">

                          <Link to="/add-user">
                            <button className="buttontop mr-5">
                              <i class="fas fa-user-plus text-white mr-2 text" ></i>Add User</button>
                          </Link>
                        </div> */}
                      </div>

                    </div>
                    <div className=" mb-4 mt-3">
                      <div className="col-md-12">
                        <div className="card m-overflow-h" style={{ border: "none" }}>
                          <div className="card-body m-p-0" >
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Old Password")}</span>
                                  <input type="password" className="form-control mt-2" placeholder={t("Old Password")}
                                    onChange={(e) => setOldPassword(e.target.value)} />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("New Password")}</span>
                                  <input type="password" className="form-control mt-2" placeholder={t("New Password")}
                                    onChange={(e) => setNewPassword(e.target.value)} />

                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Confirm Password")}</span>
                                  <input type="password" className="form-control mt-2" placeholder={t("Confirm Password")}
                                    onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>
                              </div>
                            </div>

                          </div>
                          {/* <Link to=""> */}
                          <button type="button" className="btn bg-warning text-white ml-3 " style={{ width: '20%' }}
                            onClick={resetPassword}
                          >{t("Submit")}</button>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default ChangePassword;