import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import './css/style.css'
// import 'react-dropdown/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import Login from "./Login";
import { useRoutes } from 'react-router-dom/dist';
import Dashboard from './Dashboard';
import AccountantCompany from './AccountantCompany';
import AddCompany from './AddCompany';
import EditCompany from './EditCompany';
import Users from './UserList';
import AddUser from './AddUser';
import EditUser from './EditUser';
import ChangePassword from './ChangePassword';




const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/accountant-company", element: <AccountantCompany /> },
    { path: "/add-company", element: <AddCompany /> },
    { path: "/edit-company/:id", element: <EditCompany /> },
    { path: "/user", element: <Users /> },
    { path: "/add-user", element: <AddUser /> },
    { path: "/edit-user/:id", element: <EditUser /> },
    { path: "/change-password", element: <ChangePassword /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};
export default AppWrapper;
