import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import config from '../config/config';
import { displaySuccess, displayError, authme } from '../Utils/utils';
import { useTranslation } from "react-i18next";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const dispatch = useDispatch();
  const { t } = useTranslation()
  const LoginEmail = async (e) => {
    e.preventDefault();
    // toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/admin/login';
    await axios.post(path, {
      email: email,
      password: password,
    })
      .then(function (response) {
        displaySuccess(t('Login Successful'));
        localStorage.setItem('token', response.data.data.access_token.access_token);
        setTimeout(() => window.location.href = "/accountant-company", 3000);

      })
      .catch((error) => {
        displayError(error, t);
      });
  }

  useEffect(() => {
    getAuth()
  }, [])

  const getAuth = async () => {
    var res = await authme();
    if (res !== null) {
      window.location.href = '/accountant-company'
    } else {
      localStorage.removeItem('token')
      // window.location.href = '/'
    }
  }
  return (
    <>
      <div className="Login">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="container">
              <div className="row">
                <div className="col-md-8 d-flex flex-column mx-auto">
                  <div className="card card-raised shadow-10 mt-5 mt-xl-5 mb-4"
                    style={{ borderRadius: "12px", border: "none", background: "#EFF2FC" }}>
                    <div className="card-header pb-0 text-center bg-transparent" style={{ borderBottom: "none" }}>
                      {/* <img className="mb-3" alt="..." style={{ height: "58px" }} /> */}
                      <h5 className="font-weight-bolder text-dark text-gradient">{t("Login")}</h5>
                      <p>{t("Enter your email and password to sign in")}</p>
                    </div>
                    <div className="card-body">
                      <div role="form text-left">
                        <span>{t("Email")}</span>
                        <div className="mb-3">
                          <input className="form-control mt-2 mb-2" type="text" name="username" placeholder={t("Email")}
                            onChange={e => setEmail(e.target.value)} />
                        </div>
                        <span>{t("Password")}</span>
                        <div className="mb-3">
                          <input className="form-control mt-2 mb-2" type="password" name="password" placeholder={t("Password")} onChange={e => setPassword(e.target.value)} />
                        </div>
                        {/* <Link to="/register">
                          <span>want a new account ? start it now</span>
                        </Link> */}
                        <br />
                        {/* <Link to="/dashboard"> */}
                        <button id="submit" type="submit" className="btn mt-3 bg-warning text-white"
                          onClick={LoginEmail}>{t("Login")}</button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>


      </div >
    </>
  )
}

export default Login;


