import React, { useEffect, useState } from "react";
// import img1 from '../images/user.png';
import { useSelector, useDispatch } from 'react-redux'
// import { decrement, increment } from '../../features/counterSlice'
import { displaySuccess, userLoggedIn } from "../../Utils/utils";
// import { update } from "../../features/userInfoSlice";
// import { loading } from "../../features/loaderSlice";
// import LoadingSpinner from "../LoadingSpinner";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'
import Switch from "react-switch";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Topbar = (props) => {

    const { t } = useTranslation()
    const logout = () => {
        localStorage.removeItem('token');
        displaySuccess(t('Logout Successfull'));
        setTimeout(() => window.location.href = "/", 3000);
    }

    return (
        <>
            <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top " >

                <h2 className=" d-none d-sm-block">{props.title}</h2>
                {/* Topbar Navbar */}
                <ul className="navbar-nav ml-auto">

                    <li className="nav-item dropdown no-arrow mx-1 my-3 d-none d-sm-block">
                        <LanguageSwitcher />
                    </li>
                    <li className="nav-item dropdown no-arrow mx-1 d-none d-sm-block">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="alertsDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        // onClick={() => dispatch(increment())}
                        >
                            <i class="fas fa-cog"></i>
                        </a>
                    </li>
                    <li className=" d-none d-sm-block">
                        <button
                            className="dropdown-item mt-3 " onClick={logout}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                            Logout
                        </button>

                    </li>
                </ul>
            </nav>
        </>
    )
}
export default Topbar