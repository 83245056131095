import React, { useEffect, useState } from "react";
import './AccountantCompany.css'
import { Table, thead, Tbody, th, tr, td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { displaySuccess, getAccountantCompany, getDeleteCompany } from "../Utils/utils";
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { useTranslation } from "react-i18next";
const AccountantCompany = (props) => {
	const [company, setCompany] = useState([])
	const [isDelete, setIsDelete] = useState(false);
	const [tempId, setTempId] = useState('')
	const [pageCount, setPageCount] = useState(1);
	const [current_page, setCurrentPage] = useState(1);
	const [search, setSearch] = useState('');
	const { t, i18n } = useTranslation();
	useEffect(() => {
		getCompanies(search)
	}, [search])

	const getCompanies = async (search) => {
		var res = await getAccountantCompany(search, current_page)
		setCompany(res.data)
		setPageCount(res.last_page)

	}

	const deleteCompany = async (id) => {
		var res = await getDeleteCompany(id);
		displaySuccess(res)
		getCompanies()
	}
	const handlePageClick = async (e) => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		const selectedPage = e.selected + 1;
		var res = await getAccountantCompany(search, selectedPage)
		setCompany(res.data)
		setPageCount(res.last_page)
	};
	return (
		<>
			<div id="wrapper">
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('Accountant Company')} />
						<div className="container-fluid">
							<div className="col-xl-12">
								<div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
									<div className="card-body">
										<div className="row">
											<div className="col btnAdd">
												<input type="text" className="form-control ml-4 w-25" placeholder={t("Search")}
													onChange={(e) => setSearch(e.target.value)}
												/>
												{/* <h5 className="heading1 mb-0">Recent Discount Coupon</h5> */}
												<div className="btntop">

													<Link to="/add-company">
														<button className="buttontop mr-5">
															<i class="fas fa-user-plus text-white mr-2 text" ></i>{t("Add Company")}</button>
														{/* <i class="fas fa-user-plus text-white mr-2 text" ></i>Add Company</button> */}
													</Link>
												</div>
											</div>

										</div>
										<div className=" mb-4 mt-3">
											<div className="col-md-12">
												<div className="card m-overflow-h" style={{ border: "none" }}>
													<div className="card-body m-p-0" >
														{company.length == 0 ?
															<div className="text-center" style={{ fontSize: '20px' }}>
																{t("No Company")}
															</div> :
															<div className="table-responsive">
																<Table className="table align-items-center" >
																	<thead>
																		<th>{t('Fantasy Name')}</th>
																		<th>{t('Legal Name')}</th>
																		<th>{t('CNPJ')}</th>
																		<th>{t('Created At')}</th>
																		<th>{t('Actions')}</th>
																	</thead>
																	<tbody>
																		{company.map((list) => (

																			<tr>
																				<td>{list.fantasy_name}</td>
																				<td>{list.legal_name}</td>
																				<td>{list.cnpj}</td>
																				<td><Moment format="DD/MM/YYYY">
																					{list.created_at}
																				</Moment></td>
																				<td>
																					<div className="row">
																						<Link to={"/edit-company/" + list.id}>
																							<div className="items-center mr-1 btn-outline-secondary btn btn-sm" href>
																								<i class="far fa-edit"></i>
																							</div>
																						</Link>
																						<div className="items-center mr-1 btn-outline-danger btn btn-sm"
																							onClick={() => {
																								setIsDelete(true)
																								setTempId(list.id)
																							}}
																						><i class="far fa-trash-alt" ></i>
																						</div>
																					</div>
																				</td>
																			</tr>
																		))}




																	</tbody>
																</Table>
															</div>
														}
													</div>

												</div>
											</div>
										</div>
										{company.length == 0 ? null :
											<div className="row">
												<div className="mx-auto d-block">
													<ReactPaginate
														previousLabel={t('prev')}
														nextLabel={t('next')}
														breakLabel={"..."}
														breakClassName={"break-me"}
														pageCount={pageCount}
														marginPagesDisplayed={0}
														pageRangeDisplayed={3}
														onPageChange={handlePageClick}
														containerClassName={"pagination"}
														subContainerClassName={"pages pagination"}
														activeClassName={"active"}
													/>
												</div>
											</div>
										}
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			{isDelete === true ? (
				<SweetAlert
					warning
					showCancel
					cancelBtnText={t("Cancel")}
					confirmBtnText={t("Yes")}
					confirmBtnBsStyle="danger"
					title={t('Are you sure?')}
					onConfirm={(e) => {
						deleteCompany(tempId)
						setIsDelete(false)
						// window.location.reload(false)
					}}
					onCancel={(e) => {
						setIsDelete(false)
					}}
					focusCancelBtn
				>
					{t("Do you want to delete this Company?")}
				</SweetAlert>
			) : ([]
			)}
		</>
	);
}
export default AccountantCompany;