import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { displayError, displaySuccess, getAccountantCompany, getCompanies } from "../Utils/utils";
import axios from 'axios';
import config from "../config/config";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { getUser } from "../Utils/utils";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

const EditUser = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [roleId, setRoleId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [company, setCompany] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();

  const { id } = useParams()
  useEffect(() => {
    getCompany()
    getUserDetail()
  }, [])

  const getCompany = async () => {
    var temp = []
    var res = await getCompanies()
    res.map((e) => {
      temp.push({
        value: e.id, label: e.fantasy_name
      })
    })
    setCompany(temp)
  }
  const getUserDetail = async () => {
    var tempUser = {}

    var res = await getUser(id)
    setFirstName(res.first_name)
    setLastName(res.last_name)
    setEmail(res.email)
    setPhoneNumber(res.phone_number)
    setRoleId(res.role_id)
    setCompanyId(res.accountant_company_id)
    tempUser = {
      value: res.accountant_comapny.id, label: res.accountant_comapny.fantasy_name
    }
    setSelectedOption(tempUser)
  }
  const updateUser = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/admin/user/update/' + id, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      role_id: roleId,
      accountant_company_id: companyId
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('User has been updated'));
        setTimeout(() => window.location.href = "/user", 3000);
      })
      .catch((error) => {
        displayError(error, t);
      });
  }
  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Edit User')} />
            <div className="container-fluid">
              <div className="col-xl-12">
                <div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
                  <div className="card-body">
                    <div className="row">
                      <h5 className="heading1 mb-0">{t("Edit User")}</h5>
                      <div className="btntop">
                        <Link to="/user">
                          <button className="buttontop mr-5">
                            <i class="fas fa-long-arrow-alt-left"></i> t{("Back")}</button>
                        </Link>
                      </div>
                    </div>
                    <div className=" mb-4 mt-3">
                      <div className="col-md-12">
                        <div className="card m-overflow-h" style={{ border: "none" }}>
                          <div className="card-body m-p-0" >
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("First Name")}</span>
                                  <input type="text" className="form-control mt-2" placeholder={t("First Name")}
                                    value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Last Name")}</span>
                                  <input type="text" className="form-control mt-2" placeholder={t("Last Name")}
                                    value={lastName} onChange={(e) => setLastName(e.target.value)} />

                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Email")}</span>
                                  <input type="text" className="form-control mt-2" placeholder={t("Email")}
                                    value={email} onChange={(e) => setEmail(e.target.value)} readOnly />

                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Phone number")}</span>
                                  <input type="text" className="form-control mt-2" placeholder={t("Phone Number")}
                                    value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Role")}</span>
                                  <select className="form-control mt-2" id="validationCustom04" required="" onChange={e => {
                                    setRoleId(e.target.value)
                                  }} value={roleId}>
                                    <option required>{t("Select Role")}</option>
                                    {/* <option value={"1"} >Admin</option> */}
                                    <option value={"2"} >{t("Coordinator")}</option>
                                    <option value={"3"} >{t("Accountant")}</option>
                                    {/*<option value={"4"} >Customer</option>
                                    <option value={"5"} >Customer User</option> */}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Accountant Company")}</span>
                                  <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={company}
                                    value={selectedOption}
                                    className="mt-2"
                                  />
                                  {/* <select className="form-control mt-2" id="validationCustom04" required=""
                                    onChange={e => {
                                      setCompanyId(e.target.value)
                                    }}>
                                    <option required>Select Company</option>
                                    {company.map((data) =>
                                      <option value={data.id} selected={(data.id === companyId) ? true : false}>{data.fantasy_name}</option>
                                    )}
                                  </select> */}
                                </div>
                              </div>
                            </div>

                          </div>
                          <button type="button" className="btn bg-warning text-white ml-3" onClick={updateUser}
                            style={{ width: '20%' }}
                          >{t("Submit")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default EditUser;