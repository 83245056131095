
import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import "./style.css";
import Switch from "react-switch";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [default_language, setDefaultLanguage] = useState('pt');
  const [checked, setChecked] = useState(false);

  const changeLanguage = lng => {
    localStorage.setItem('langauge_selected', lng);
    setDefaultLanguage(lng)
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    if (localStorage.getItem('langauge_selected')) {
      i18n.changeLanguage(localStorage.getItem('langauge_selected'));
      setDefaultLanguage(localStorage.getItem('langauge_selected'))
    } else {
      i18n.changeLanguage('pt');
      setDefaultLanguage('pt')
    }
  }, [])
  const handleChange = (checked) => {
    // console.log(checked)
    setChecked(checked)
    if (checked === true) {
      localStorage.setItem('langauge_selected', 'pt');
      setDefaultLanguage('pt')
      i18n.changeLanguage('pt');
      // window.location.href = `${router.asPath}`
    } else {
      localStorage.setItem('langauge_selected', 'en');
      setDefaultLanguage('en')
      i18n.changeLanguage('en');
      // window.location.href = `/en/${router.asPath}`
    }
  }
  return (
    <>
      <Switch
        checked={checked}
        onChange={handleChange}
        handleDiameter={28}
        offColor="#c7c7c7"
        onColor="#c7c7c7"
        offHandleColor="#737373"
        onHandleColor="#737373"
        height={40}
        width={100}
        borderRadius={6}
        activeBoxShadow="0px 0px 1px 2px #fffc35"
        style={{ display: 'flex', justifyContent: 'center' }}
        uncheckedIcon={
          <span class="fi fi-us" style={{ fontSize: '25px', marginLeft: '7px', marginTop: '7px' }}></span>
        }
        checkedIcon={
          <span class="fi fi-br" style={{ fontSize: '25px', marginLeft: '10px', marginTop: '7px' }}></span>
        }
        uncheckedHandleIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 20
            }}
          >
            En
          </div>
        }
        checkedHandleIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              color: "#fff",
              fontSize: 18,
              // width: '200px'
            }}
          >
            Pt
          </div>
        }
        className="react-switch lang"
        id="small-radius-switch"
      />
      {/* <select className="form-control" onChange={(e) => changeLanguage(e.target.value)} value={default_language}>
        <option value="en"   >{t('English')}</option>
        <option value="pt" >{t('Português')}</option>
      </select> */}
    </>
  );
}

export default LanguageSwitcher;
