import React, { useEffect, useState } from "react";
// import './CouponList.css';
import { Table, thead, Tbody, th, tr, td } from 'react-super-responsive-table';
import { Link, useParams } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from "react-datepicker";
// import { displayError, displaySuccess } from "../../Utils/utils";
import axios from 'axios';
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { displayError, displaySuccess, getCompanyDetail } from "../Utils/utils";
import ReactInputMask from "react-input-mask";
import config from "../config/config";
import { useTranslation } from "react-i18next";

const EditCompany = (props) => {
  const [fantasyName, setFantasyName] = useState('')
  const [legalName, setLegalName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [cnpjInter, setCnpjInter] = useState('')
  const { id } = useParams();
  let [mask, setMask] = React.useState(null);
  const { t } = useTranslation();

  // const dispatch = useDispatch();
  const handleChange = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("99.999.999/9999-99");
    setCnpj(ev.target.value);
    setCnpjInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };

  useEffect(() => {
    getDetails(id)
  }, [])

  const getDetails = async (id) => {
    var res = await getCompanyDetail(id);
    setFantasyName(res.fantasy_name)
    setLegalName(res.legal_name)
    setCnpj(res.cnpj)
    setCnpjInter(res.cnpj)
  }

  const onFormSubmit = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/admin/accountant-company/update/' + id, {
      fantasy_name: fantasyName,
      legal_name: legalName,
      cnpj: cnpj
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('Company has been Updated'));
        setTimeout(() => window.location.href = "/accountant-company", 3000);
      })
      .catch((error) => {
        displayError(error, t);
      });

  };
  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Edit Accountant Company')} />
            <div className="container-fluid">
              <div className="col-xl-12">
                <div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
                  <div className="card-body">
                    <div className="row">
                      <h5 className="heading1 mb-0">{t("Edit Accountant Company")}</h5>
                      <div className="btntop">
                        <Link to="/accountant-company">
                          <button className="buttontop mr-5">
                            <i class="fas fa-long-arrow-alt-left"></i> {t('Back')}</button>
                        </Link>
                      </div>
                    </div>
                    <div className=" mb-4 mt-3">
                      <div className="col-md-12">
                        <div className="card m-overflow-h" style={{ border: "none" }}>
                          <div className="card-body m-p-0" >
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Fantasy Name")}</span>
                                  <input type="text" className="form-control mt-2" placeholder={t("Fantasy Name")}
                                    value={fantasyName}
                                    onChange={(e) => setFantasyName(e.target.value)} />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">{t("Legal Name")}</span>
                                  <input type="text" className="form-control mt-2" placeholder={t("Legal Name")}
                                    value={legalName}
                                    onChange={(e) => setLegalName(e.target.value)}
                                  />

                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <span className="form-control-label">CNPJ</span>
                                  <ReactInputMask
                                    className="form-control mt-2"
                                    mask={mask}
                                    onChange={handleChange}
                                    maskChar="_"
                                    value={cnpj}
                                    placeholder={("CNPJ")}
                                    {...props}
                                  />
                                </div>
                              </div>
                            </div>

                          </div>
                          {/* <Link to=""> */}
                          <button type="button" className="btn bg-warning text-white ml-3" style={{ width: '20%' }}
                            onClick={onFormSubmit}
                          >{t("Submit")}</button>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default EditCompany;