import React, { useEffect, useState } from "react";
// import './AccountantCompany.css'
import { Table, thead, Tbody, th, tr, td } from 'react-super-responsive-table';
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { displaySuccess, getDeleteUser, getUser, getUserListing } from "../Utils/utils";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";

const Users = (props) => {
  const [tempId, setTempId] = useState('');
  const [isDelete, setIsDelete] = useState(false)
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const getUserList = async (search) => {
    var res = await getUserListing(search, current_page)
    setUsers(res.data)
    setPageCount(res.last_page)
  }
  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var res = await getUserListing(search, selectedPage)
    setUsers(res.data)
    setPageCount(res.last_page)
  };
  useEffect(() => {
    getUserList(search)
  }, [search])

  const deleteUser = async (id) => {
    var res = await getDeleteUser(id)
    displaySuccess(res)
    getUserList(search)
  }
  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Users')} />
            <div className="container-fluid">
              <div className="col-xl-12">
                <div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col btnAdd">
                        <input type="text" className="form-control ml-4 w-25" placeholder={t("Search")}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        {/* <h5 className="heading1 mb-0">Recent Discount Coupon</h5> */}
                        <div className="btntop">

                          <Link to="/add-user">
                            <button className="buttontop mr-5">
                              <i class="fas fa-user-plus text-white mr-2 text" ></i>{t("Add User")}</button>
                          </Link>
                        </div>
                      </div>


                    </div>
                    <div className=" mb-4 mt-3">
                      <div className="col-md-12">
                        <div className="card m-overflow-h" style={{ border: "none" }}>

                          <div className="card-body m-p-0" >
                            <div className="table-responsive">
                              <Table className="table align-items-center" >
                                <thead>
                                  <th>{t('Name')}</th>
                                  <th>{t('Email')}</th>
                                  <th>{t('Phone Number')}</th>
                                  <th>{t('Role')}</th>
                                  <th>{t('Accountant Company')}</th>
                                  <th>{t('Actions')}</th>
                                </thead>
                                <tbody>
                                  {users.length == 0 ?
                                    <tr>
                                      <td colSpan={6} style={{ textAlign: 'center' }}>
                                        No User
                                      </td>
                                    </tr>
                                    : users.map((list) => (

                                      <tr>
                                        <td>{list.first_name + " " + list.last_name}</td>
                                        <td>{list.email}</td>
                                        <td>{list.phone_number}</td>
                                        <td>{list.role?.name}</td>
                                        <td>{list.accountant_comapny == null ? "" : list.accountant_comapny.fantasy_name}</td>
                                        <td>
                                          <div className="row">
                                            <Link to={"/edit-user/" + list.id}>
                                              <div className="items-center mr-1 btn-outline-secondary btn btn-sm" href>
                                                <i class="far fa-edit"></i>
                                              </div>
                                            </Link>
                                            <div className="items-center mr-1 btn-outline-danger btn btn-sm"
                                              onClick={() => {
                                                setIsDelete(true)
                                                setTempId(list.id)
                                              }}
                                            ><i class="far fa-trash-alt" ></i>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}



                                </tbody>
                              </Table>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mx-auto d-block">
                      <ReactPaginate
                        previousLabel={t('prev')}
                        nextLabel={t('next')}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDelete === true ? (
        <SweetAlert
          warning
          showCancel
          cancelBtnText={t("Cancel")}
          confirmBtnText={t("Yes")}
          confirmBtnBsStyle="danger"
          title={t('Are you sure?')}
          onConfirm={(e) => {
            deleteUser(tempId)
            setIsDelete(false)
            // window.location.reload(false)
          }}
          onCancel={(e) => {
            setIsDelete(false)
          }}
          focusCancelBtn
        >
          {t("Do you want to delete this user?")}
        </SweetAlert>
      ) : ([]
      )}
    </>
  );
}
export default Users;